<script>
import axios from 'axios'
// import simplebar from "simplebar-vue";

import i18n from '../i18n'
import { authComputed } from '@/state/helpers'
import { authUsuarioComputed } from '@/state/helpers'
import { mapActions } from 'vuex'
/**
 * Nav-bar Component
 */
export default {
  props: {
    value: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      languages: [
        {
          flag: require('@/assets/images/flags/us.jpg'),
          language: 'en',
          title: 'English'
        },
        {
          flag: require('@/assets/images/flags/french.jpg'),
          language: 'fr',
          title: 'French'
        },
        {
          flag: require('@/assets/images/flags/spain.jpg'),
          language: 'es',
          title: 'Spanish'
        },
        {
          flag: require('@/assets/images/flags/chaina.png'),
          language: 'zh',
          title: 'Chinese'
        },
        {
          flag: require('@/assets/images/flags/arabic.png'),
          language: 'ar',
          title: 'Arabic'
        }
      ],
      userTalanaInfo: null,
      lan: i18n.locale,
      text: null,
      flag: null,
      value2: null,
      myVar: 1
    }
  },
  // components: { simplebar },
  mounted() {
    this.value2 = this.languages.find((x) => x.language === i18n.locale)
    this.text = this.value2.title
    this.flag = this.value2.flag
  },
  methods: {
    ...mapActions({
      getUserFromEmail: 'user/getUserFromEmail'
    }),
    async getInfoTalanaUser(email) {
      const resp = await this.getUserFromEmail({ email })
      this.userTalanaInfo = resp
    },
    logout() {
      this.$router.push({
        name: 'logout'
      })
    },
    toggleMenu() {
      this.$parent.toggleMenu()
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar()
    },
    initFullScreen() {
      document.body.classList.toggle('fullscreen-enable')
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen()
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale
      this.text = country
      this.flag = flag
      i18n.locale = locale
    },
    logoutUser() {
      // eslint-disable-next-line no-unused-vars
      axios.get('http://127.0.0.1:8000/api/logout').then((res) => {
        this.$router.push({
          name: 'default'
        })
      })
    },
    clickButton() {
      this.$emit('input', !this.value)
    }
  },
  computed: {
    ...authComputed,
    ...authUsuarioComputed
  },
  watch: {
    user: {
      immediate: true,
      handler: function(user) {
        if (user) {
          this.getInfoTalanaUser(user.email)
        }
      }
    }
  }
}
</script>

<template>
  <v-app-bar app>
    <v-app-bar-nav-icon @click="clickButton"></v-app-bar-nav-icon>
    <!-- <v-toolbar-title>Application</v-toolbar-title> -->
    <v-spacer></v-spacer>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-avatar color="primary" size="48">
            <img
              v-if="userTalanaInfo && userTalanaInfo.photo_url"
              :src="userTalanaInfo.photo_url"
              alt="Profile Image"
            />
            <span v-else-if="user && user.nombre_usuario" class="white--text text-h5">{{
              user.nombre_usuario.charAt(0)
            }}</span>
          </v-avatar>
          <span class="d-inline-block mx-3" v-if="user && user.nombre_usuario">{{
            user.nombre_usuario
          }}</span>
        </div>
      </template>

      <v-card class="mx-auto" max-width="300" tile>
        <v-list>
          <v-list-item-group color="primary">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link to="/usuarios/perfil" v-slot="{ navigate }" custom>
                    <span @click="navigate" @keypress.enter="navigate">
                      {{ $t('navbar.dropdown.henry.list.profile') }}
                    </span>
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-danger" @click="logout">
                  {{ $t('navbar.dropdown.henry.list.logout') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>
