<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="700px"
      scrollable
      @keydown="executeCloseModalCheckPassword"
      @click:outside="executeCloseModalCheckPassword"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            Verificar contraseña para acceder
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form @submit.prevent="checkPassword" class="my-5">
            <v-text-field
              type="password"
              class="py-5"
              v-model="form.password"
              hide-details
              label="Ingresa la contraseña"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            class="text-white"
            @click="checkPassword"
          >
            <small>
              Verificar
            </small>
          </v-btn>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalCheckPassword"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
export default {
  props: {
    modalAction: {
      type: Boolean,
      required: true,
    },
    closeModalCheckPassword: {
      type: Function,
      default: () => {},
    },
    passwordToCheck: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        password: "",
      },
    };
  },
  methods: {
    executeCloseModalCheckPassword($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.form.password = "";
        this.closeModalCheckPassword($event);
      }
    },
    checkPassword() {
      if (this.form.password === this.passwordToCheck) {
        this.form.password = "";
        this.closeModalCheckPassword();
        return this.$emit("passwordChecked");
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "La contraseña es incorrecta",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },
};
</script>

<style></style>
