<script>
import { authUsuarioComputed } from "@/state/helpers";
import { menuItems } from "@/components/menu.js";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import config from "@/config";
import CheckPassword from "../commons/CheckPassword.vue";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
/**
 * Vertical layout
 */
export default {
  components: { CheckPassword },
  data() {
    return {
      password_manteiners: config.password_manteiners,
      currentLinkTo: null,
      jsonMenu: menuItems,
      dialogCheckPassword: false,
    };
  },
  methods: {
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    obtenerPermisos(nombreModulo) {
      let permisos = permiso.obtenerPermisos(this.user, nombreModulo);
      return permisos;
    },
    /**
     * Redirect to link or check password first
     * @param item menuItem
     */
    toLinkOrCheckPassword(link, passwordRequired, outside = false) {
      if (outside) {
        return window.open(link, "_blank");
      }
      this.currentLinkTo = null;
      if (!passwordRequired) {
        this.$router.push(link);
      } else {
        this.currentLinkTo = link;
        this.openModalCheckPassword();
      }
    },
    openModalCheckPassword() {
      this.dialogCheckPassword = true;
    },
    closeModalCheckPassword($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogCheckPassword = false;
      }
    },
  },
  computed: {
    ...authUsuarioComputed,
    enviroment() {
      return process.env.NODE_ENV;
    },
    filterJsonMenu() {
      const filteredMain = this.jsonMenu.filter(
        (element) =>
          this.obtenerPermisos(element.nombreModulo) && element.active !== null
      );
      filteredMain.forEach((element, index) => {
        if (element.subItems) {
          element.subItems = element.subItems.filter(
            (val) =>
              this.obtenerPermisos(val.nombreModulo) && val.active !== null
          );
        }
      });
      return filteredMain;
    },
  },
};
</script>
<template>
  <v-container class="pa-0">
    <v-list class="pa-0">
      <v-list-item class="pa-0">
        <v-list-item-title>
          <v-sheet color="#13141a" class="pa-0 text-center">
            <router-link to="/" class="logo d-block">
              <img
                src="@/assets/images/copec/logo-copec-flux.png"
                alt
                class="mt-8"
                height="80"
              />
            </router-link>
          </v-sheet>
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list class="py-0 list-parent-class">
      <template v-for="(item, i) in filterJsonMenu">
        <v-list-item
          v-if="!item.subItems"
          :key="i"
          :to="!item.outside ? item.link : null"
          :href="item.outside ? item.link : null"
          :target="item.outside ? '_blank' : null"
          :active-class="item.color"
        >
          <v-list-item-action class="mr-3">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-title v-text="$t(item.label)" />
        </v-list-item>
        <v-list-group
          v-else
          no-action
          no-transition
          v-model="item.active"
          :key="$t(item.label)"
        >
          <template v-slot:activator>
            <v-list-item-action class="mr-3">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ $t(item.label) }}</v-list-item-title>
          </template>
          <v-list-item
            v-for="(subItem, index) in item.subItems"
            @click="
              toLinkOrCheckPassword(subItem.link, subItem.passwordRequired)
            "
            :key="$t(subItem.label)"
          >
            <v-list-item-title
              style="margin-left: -2rem; font-size: 12px"
              v-if="!subItem.subItems"
              >{{ $t(subItem.label) }}</v-list-item-title
            >
            <v-list-group
              style="margin-left: -4.5rem"
              v-if="subItem.subItems"
              v-model="subItem.active"
              :key="`${$t(subItem.label)}-${index}`"
              sub-group
              multiple
            >
              <template v-slot:activator>
                <v-list-item-title style="font-size: 12px">{{
                  $t(subItem.label)
                }}</v-list-item-title>
              </template>
              <v-list-item
                v-for="(sub2Item, index2) in subItem.subItems"
                @click="
                  toLinkOrCheckPassword(
                    sub2Item.link,
                    sub2Item.passwordRequired
                  )
                "
                :key="$t(sub2Item.label)"
                v-if="!sub2Item.enviroment || sub2Item.enviroment == enviroment"
              >
                <v-list-item-title
                  v-if="!sub2Item.subItems"
                  style="margin-left: -2rem; font-size: 12px"
                  class="mx-3"
                  >{{ $t(sub2Item.label) }}</v-list-item-title
                >
                <v-list-group
                  style="margin-left: -2rem"
                  v-if="sub2Item.subItems"
                  v-model="sub2Item.active"
                  sub-group
                  no-action
                  no-transition
                >
                  <template v-slot:activator>
                    <v-list-item-title style="font-size: 12px">{{
                      $t(sub2Item.label)
                    }}</v-list-item-title>
                  </template>
                  <v-list-item
                    v-for="sub3Item in sub2Item.subItems"
                    :key="`${$t(sub3Item.label)}-${index}`"
                    @click="
                      toLinkOrCheckPassword(
                        sub3Item.link,
                        sub3Item.passwordRequired,
                        sub3Item.outside ? true : false
                      )
                    "
                  >
                    <v-list-item-title
                      style="margin-left: -2.5rem; font-size: 12px"
                      >{{ $t(sub3Item.label) }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list-group>
              </v-list-item>
            </v-list-group>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
    <small class="version text-white text-center">Ver. 1.4.0</small>
    <check-password
      :modalAction="dialogCheckPassword"
      :closeModalCheckPassword="closeModalCheckPassword"
      :passwordToCheck="password_manteiners"
      @passwordChecked="toLinkOrCheckPassword(currentLinkTo, false)"
    ></check-password>
  </v-container>
</template>
<style scoped>
.version {
  position: absolute;
  bottom: 0px;
  padding: 3px 10px;
  background: #13141a;
  width: 93%;
}
.list-parent-class >>> .v-icon,
.list-parent-class >>> .v-list-item__icon .v-icon {
  color: #a6b0cf !important;
}
.v-list-item__title {
  font-size: 14px;
  color: #a6b0cf;
}
</style>
